import Request from '../system/Request';
import Media from './Account/Media';
import Notifications from './Account/Notifications';
import Messages from './Account/Messages';

const Account = {
  Media: Media,
  Notifications: Notifications,
  Messages: Messages,

  login: (data, isTwoStep) => {
    const body = {
      username: data.username,
      password: data.password
    };

    if (isTwoStep) {
      body._two_step = Object.keys(data.two_step).map((k) => data.two_step[k]).join('');
    }

    return new Promise((resolve, reject) => {
      (new Request()).post('/api/login_check', JSON.stringify(body), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  register: (data, locations) => {
    const orientation = data.orientation.split('->');
    const findLocation = locations.find((l) => l.key === data.location.key);

    const body = {
      gender: orientation[0],
      seeking: orientation[1],
      dob: data.dob,
      location: findLocation.key,
      username: data['username'],
      email: data['email'],
      password: data['password']
    };

    return new Promise((resolve, reject) => {
      (new Request()).post('/api/users/create', JSON.stringify(body), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  recover: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/users/recover', JSON.stringify({
        email: data['email'],
        password: btoa(data['new_password'])
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, false).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  get: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/users/me', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  counts: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/users/counts', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  toggleSafeSearch: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/users/me/safe-search', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  search: (email) => {
    return new Promise((resolve, reject) => {
      if (0 === email.length) {
        reject();
        return;
      }

      (new Request()).get('/api/users/search/' + email, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  updateAccount: (data, locations) => {
    const orientation = data.orientation.split('->');
    const findLocation = locations.find((l) => l.key === data.location.key);

    const body = {
      gender: orientation[0],
      seeking: orientation[1],
      location: findLocation.key,
      bodyType: data.bodyType,
      description: data.description,
      drinking: data.drinking,
      ethnicity: data.ethnicity,
      eyeColour: data.eyeColour,
      hairColour: data.hairColour,
      hairDownThere: data.hairDownThere,
      haveChildren: data.haveChildren,
      height: data.height,
      piercings: data.piercings,
      relationshipStatus: data.relationshipStatus,
      sexuality: data.sexuality,
      smoking: data.smoking,
      tattoos: data.tattoos,
    };

    return new Promise((resolve, reject) => {
      (new Request()).put('/api/users/me/update', JSON.stringify(body), {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  changePassword: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/users/me/password', JSON.stringify(data), {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  updateKeys: (publicKey, privateKey) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/users/me/keys', JSON.stringify({
        public: btoa(JSON.stringify(publicKey)),
        private: btoa(JSON.stringify(privateKey))
      }, true), {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Account;
